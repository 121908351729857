import React from "react";
import {useTranslation} from "react-i18next";

export default function AdvanceButtons({isFirstStep, isLastStep, onBack}) {
    const {t} = useTranslation('pages/tournament/first_access_modal');

    return (<div className="d-flex flex-column flex-md-row justify-content-center align-items-md-center mt-4"
                 style={{gap: 15}}>

        <button type="submit" className="btn btn-success">
            {isLastStep ? t('buttons.start_game') : t('buttons.continue')}
        </button>

        <button type="button" className="btn btn-sm btn-outline-secondary border-0"
                onClick={onBack} disabled={isFirstStep}>
            {t('buttons.back')}
        </button>
    </div>);
}