import React, {useMemo} from "react";
import {Button, Modal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useSelector} from "react-redux";
import ToastUtil from "../../../utils/ToastUtil";
import {useTranslation} from "react-i18next";

const CONTACT_EMAIL = 'help.torneio@startupmundi.com.br';

export default function HelpModal({onClose}) {
    const {t, i18n} = useTranslation('pages/tournament/help_modal');

    const gameInfo = useSelector(state => state.game.gameInfo);
    const userEmail = useSelector(state => state.session.user.email);

    const handleCopyContactEmail = () => {
        navigator.clipboard.writeText(CONTACT_EMAIL);
        ToastUtil.toastSuccess(t('toasts.link_copied.title'), t('toasts.link_copied.message'));
    }

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text);
        ToastUtil.toastSuccess(t('toasts.link_copied.title'), t('toasts.link_copied.message'));
    }

    const helpText = useMemo(() => {
        const text = t('texts.help_message', {
            player: userEmail,
            tournament: gameInfo.name,
            game: gameInfo.game.toUpperCase(),
        });

        return {
            wpp: encodeURIComponent(text),
            mail: text.replaceAll('\r\n', ' | ')
        }
    }, [gameInfo, userEmail]);

    return (
        <Modal show={true} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>{t('texts.title')}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <h5>{t('texts.greetings')}</h5>
                <p>{t('texts.subtitle')}</p>

                <hr/>

                <div className="text-center">
                    <a className="btn btn-success text-white px-3" data-action="share/whatsapp/share"
                       href={"https://wa.me/554891079686?text=" + helpText.wpp} target="_blank">

                        <FontAwesomeIcon icon={['fab', 'whatsapp']} className="mr-2"
                                         style={{fontSize: '140%'}}/>

                        {t('buttons.wpp')}
                    </a>
                </div>

                <hr/>

                <p className="mb-0">{t('texts.mail_title')}</p>

                <div className="d-flex mb-3">
                    <button type="button" className="btn btn-secondary mr-2 py-0 px-2"
                            onClick={() => handleCopy(CONTACT_EMAIL)}>
                        <FontAwesomeIcon icon={['fas', 'copy']}/>
                    </button>

                    <a href={`mailto:${CONTACT_EMAIL}&subject=${t('texts.mail_subject')}&body=${helpText.mail}`}
                       className="font-weight-bold">
                        {CONTACT_EMAIL}
                    </a>
                </div>


                <p className="mb-1">{t('texts.mail_info')}</p>

                <div className="d-flex mb-3">
                    <button type="button" className="btn btn-secondary mr-2 py-0 px-2"
                            onClick={() => handleCopy(t('texts.mail_subject'))}>
                        <FontAwesomeIcon icon={['fas', 'copy']}/>
                    </button>

                    <p className="mb-0">{t('texts.mail_subject')}</p>
                </div>


                <p className="mb-2">{t('texts.mail_body')}</p>

                <div className="d-flex">
                    <button type="button" className="btn btn-secondary mr-2 py-0 px-2"
                            onClick={() => handleCopy(helpText.mail)}>
                        <FontAwesomeIcon icon={['fas', 'copy']}/>
                    </button>

                    <p className="mb-0">{helpText.mail}</p>
                </div>

                <hr/>

                <p className="mt-4 mb-0">{t('texts.footer_info')}</p>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>{t('buttons.close')}</Button>
            </Modal.Footer>
        </Modal>
    )
}