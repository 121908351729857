import React, {useEffect, useMemo, useState} from "react";

import "./Join.scss";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import ButtonSpinner from "components/ButtonSpinner";
import FadeIn from "assets/plugins/react-fade-in/FadeIn";
import OverlayUtil from "utils/OverlayUtil";
import {OVERLAY_THEME, PLAYER_ACCESS_CONTROL, SESSION_STATUS} from "config/CONSTANTS";
import ToastUtil from "utils/ToastUtil";
import GameSessionService from "services/common/GameSessionService";
import AuthService from "services/player/AuthService";
import {PLAYER_ROUTES, PUBLIC_ROUTES, TERMS_ROUTES} from "navigation/ROUTES";
import store from "redux/store";

import {useSelector} from "react-redux";

import SessionClosedInfo from "pages/common/sessionStatus/SessionClosedInfo";
import SessionPausedInfo from "pages/common/sessionStatus/SessionPausedInfo";
import SessionNotStartedInfo from "pages/common/sessionStatus/SessionNotStartedInfo";
import SessionWithRestrictedAccessInfo from "../../common/sessionStatus/SessionWithRestrictedAccessInfo";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import BugReportModal from "../../common/BugReportModal/BugReportModal";
import LoggerService from "services/common/LoggerService";
import {Trans, useTranslation} from "react-i18next";
import SessionReduxService from "../../../services/redux/SessionReduxService";
import SessionNotFoundInfo from "../../common/sessionStatus/SessionNotFoundInfo";
import ConfirmExistingPlayerModal from "./components/ConfirmExistingPlayerModal";

export default function Join() {
    const navigate = useNavigate();
    const {t} = useTranslation(['pages/player/join', 'common'], {useSuspense: true});
    const {gameSession} = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const sessionName = useSelector(state => state.game.gameInfo.name);
    const theme = useSelector(state => state.theme);

    const [decodedGameSessionId, setDecodedGameSessionId] = useState(false);
    const [nickname, setNickname] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [status, setStatus] = useState('');

    const [showKickedInfo, setShowKickedInfo] = useState(true);
    const [kickedInfo, setKickedInfo] = useState(null)

    const [showUniqueNicknameInfo, setShowUniqueNicknameInfo] = useState(false);
    const [showNicknameOnlineInfo, setShowNicknameOnlineInfo] = useState(false);
    const [chosenPlayerData, setChosenPlayerData] = useState({});
    const [showMaxPlayerCountExceededInfo, setShowMaxPlayerCountExceededInfo] = useState(false);

    const [showBugReportModal, setShowBugReportModal] = useState(false);
    const handleShowBugReportModal = () => setShowBugReportModal(true);
    const handleCloseBugReportModal = () => setShowBugReportModal(false);

    const [showConfirmExistingPlayerModal, setShowConfirmExistingPlayerModal] = useState(false);
    const handleCloseShowConfirmExistingPlayerModal = () => {
        setNickname('');
        setShowConfirmExistingPlayerModal(false);
        setShowUniqueNicknameInfo(true);
    }

    const now = useMemo(() => (new Date()).getUTCFullYear(), []);

    const handleSubmit = async (evt) => {
        evt.preventDefault();

        const params = {nickname: nickname.trim(), gameSessionId: decodedGameSessionId};

        if (params.nickname.length < 4 || params.nickname.length > 20) {
            setNickname(params.nickname);
            ToastUtil.toastDanger(t('inputs.nickname.validation_error.title'), t('inputs.nickname.validation_error.message'));
            return;
        }

        setIsLoading(true);
        setChosenPlayerData({});
        setShowNicknameOnlineInfo(false);
        setShowUniqueNicknameInfo(false);
        setShowKickedInfo(false);
        setKickedInfo(null);
        setShowMaxPlayerCountExceededInfo(false);

        AuthService.join(window.location.pathname, params).then(response => {
            if (response === true) {
                OverlayUtil.show();

                // await 250ms to prevent the screen from flashing white
                setTimeout(() => navigate(PLAYER_ROUTES.LOBBY), 250);
            } else {
                if (response?.maxPlayers === true) {
                    setShowMaxPlayerCountExceededInfo(true);
                } else if (response?.nicknameOnline === true) {
                    setChosenPlayerData({nickname});
                    setShowNicknameOnlineInfo(true);
                } else if (response?.nicknameAlreadyUsed === true) {
                    setChosenPlayerData({nickname, team: response?.user?.team ?? false});
                    setShowConfirmExistingPlayerModal(true);
                } else {
                    ToastUtil.toastDanger(t('toasts.login_failed.title'), t('toasts.login_failed.message'));
                }

                setIsLoading(false);
            }
        }).catch(reason => {
            console.error(reason);
            ToastUtil.toastDanger(t('toasts.login_failed.title'), t('toasts.login_failed.message'));
            setIsLoading(false);

            LoggerService.error(reason, {action: 'join game  - player', params});
        });
    };

    useEffect(() => {
        OverlayUtil.toggleLoadingOverlay(true, t('overlays.checking_session_status'), OVERLAY_THEME.DARK);

        const checkGameStatus = async () => {
            const decodedSessionId = decodeURIComponent(gameSession);

            GameSessionService.checkGameSessionStatus(decodedSessionId).then(async response => {
                if (response && response.status && response.gameSessionId) {
                    const playerAccessControl = store.getState().game.playerAccessControl;
                    setDecodedGameSessionId(response.gameSessionId);

                    if (playerAccessControl === PLAYER_ACCESS_CONTROL.NONE) {
                        setStatus(SESSION_STATUS.LIMITED);
                        return false;
                    }

                    if (playerAccessControl === PLAYER_ACCESS_CONTROL.IN_SESSION) {
                        const didJoin = await AuthService.rejoin(
                            window.location.pathname,
                            response.gameSessionId
                        );

                        if (!didJoin)
                            setStatus(SESSION_STATUS.LIMITED);

                        return didJoin;
                    }

                    return AuthService.rejoin(window.location.pathname, response.gameSessionId);
                }

                setStatus(response.sessionStatus || SESSION_STATUS.NOT_FOUND);

                return false;
            }).then(response => {
                if (response === true) {
                    navigate(PLAYER_ROUTES.LOBBY);
                } else {
                    OverlayUtil.toggleLoadingOverlay(false);
                }
            }).catch(reason => {
                console.error(reason);
                OverlayUtil.toggleLoadingOverlay(false);
                LoggerService.error(reason, {action: 'check game status - player'});
                SessionReduxService.updateHasFatalError(true);
            });
        };

        document.title = t('common:app_title');

        checkGameStatus();
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);

    useEffect(() => {
        if (searchParams.has('kicked')) {
            setKickedInfo(Object.fromEntries(searchParams.entries()));
            setSearchParams({});
        }
    }, []);

    if (status === SESSION_STATUS.NOT_STARTED) return (<SessionNotStartedInfo/>);
    if (status === SESSION_STATUS.CLOSED) return (<SessionClosedInfo/>);
    if (status === SESSION_STATUS.PAUSED) return (<SessionPausedInfo/>);
    if (status === SESSION_STATUS.LIMITED) return (<SessionWithRestrictedAccessInfo/>);
    if (status === SESSION_STATUS.NOT_FOUND) return (<SessionNotFoundInfo/>);

    return (<div id="player-login-page" className="row h-100 w-100 m-0">
            <div className="col-sm-12 col-md-6 col-xl-5 position-relative">
                <div className="form px-5 w-100 d-flex flex-column justify-content-center">
                    <FadeIn>
                        <div className="w-100 text-center d-md-none mb-5 pt-3">
                            <img alt="Mundi Game Experiences logo" src={theme.logos.join_page_logo}
                                 className="logo-header"/>
                        </div>

                        <h2 className="mb-1 text-warning">{t('texts.greetings')}</h2>
                        <h4 className="mb-4">{t('common:app_name')}</h4>
                    </FadeIn>

                    <FadeIn>
                        <h6 className="mb-5">
                            <Trans t={t} values={{session: sessionName}} i18nKey={'texts.session_info'}
                                   components={{session: <b className="text-warning"/>}}/>
                        </h6>
                    </FadeIn>

                    {showNicknameOnlineInfo && (
                        <div className="alert alert-warning animate__animated animate__faster animate__fadeInDown">
                            <Trans t={t} i18nKey={'texts.nickname_already_online'}
                                   values={{nickname: chosenPlayerData.nickname}}
                                   components={{b: <b/>}}/>
                        </div>
                    )}

                    {showUniqueNicknameInfo && (
                        <div className="alert alert-warning animate__animated animate__faster animate__fadeInDown">
                            <Trans t={t} i18nKey={'texts.nickname_unique_info'} components={{b: <b/>}}/>
                        </div>
                    )}

                    {showMaxPlayerCountExceededInfo && (
                        <div className="alert alert-warning animate__animated animate__faster animate__fadeInDown">
                            <Trans t={t} i18nKey={'texts.max_player_count_exceeded'} components={{b: <b/>}}/>
                        </div>
                    )}

                    {kickedInfo && !kickedInfo['rejoin'] && showKickedInfo && (
                        <div className="alert alert-warning animate__animated animate__faster animate__fadeInDown">
                            <Trans t={t} i18nKey={'texts.disconnected_by_gm_info'} components={{b: <b/>}}/>

                            {kickedInfo['nickname'] && (<>
                                <span className="my-1 d-block"/>

                                <Trans t={t} i18nKey={'texts.previous_nickname'}
                                       values={{nickname: kickedInfo['nickname']}}
                                       components={{b: <b/>}}/>
                            </>)}
                        </div>
                    )}

                    {kickedInfo && kickedInfo['rejoin'] && showKickedInfo && (
                        <div className="alert alert-warning animate__animated animate__faster animate__fadeInDown">
                            <Trans t={t} i18nKey={'texts.disconnected_by_rejoin'} components={{b: <b/>}}/>

                            {kickedInfo['nickname'] && (<>
                                <span className="my-1 d-block"/>

                                <Trans t={t} i18nKey={'texts.previous_nickname'}
                                       values={{nickname: kickedInfo['nickname']}}
                                       components={{b: <b/>}}/>
                            </>)}
                        </div>
                    )}

                    <form onSubmit={handleSubmit}>
                        <div className={'form-group'}>
                            <label className="required">{t('inputs.nickname.label')}</label>
                            <input className="form-control" required={true} autoFocus={true}
                                   value={nickname} autoComplete="nope"
                                   onChange={e => setNickname(e.target.value)} min={3}/>
                            <span className="form-text small text-muted">{t('inputs.nickname.tip')}</span>
                        </div>

                        <ButtonSpinner type="submit" showAnimation={isLoading}>{t('buttons.play')}</ButtonSpinner>

                        <div className="small text-muted mt-1">
                            <p className="m-0 d-inline">{t('texts.terms_info')} </p>
                            <a href={TERMS_ROUTES.TERMS_OF_USE} target="_blank" className="btn btn-link d-inline p-0 "
                               style={{fontSize: '85%'}}>{t('buttons.terms_of_use')}
                            </a>
                        </div>
                    </form>

                    <div className="mt-2 mb-0 p-0 text-muted small">
                        <p className="mb-1">
                            <a href={TERMS_ROUTES.COOKIES_POLICY} target="_blank"
                               className="text-muted mr-1">{t('common:cookies_policy')}</a>
                            <a href={TERMS_ROUTES.PRIVACY_POLICY} target="_blank"
                               className="text-muted">{t('common:privacy_policy')}</a>
                        </p>

                        <p className="mb-1">
                            @ {now}

                            <a href="https://www.startupmundi.com.br/" rel="noreferrer"
                               className="text-muted text-decoration-underline ml-1"
                               target="_blank">
                                {t('common:company_name')}</a>. {t('common:rights_reserved')}
                        </p>

                        <a className="mr-2 mb-2 mb-md-0 text-muted" href={PUBLIC_ROUTES.VERIFY_CONNECTION}
                           target="_blank">
                            <FontAwesomeIcon icon={['fas', 'signal']}/> {t('common:btn_test_connection')}
                        </a>

                        <button className="btn btn-link  text-muted p-0 text-decoration-none btn-report-bug"
                                style={{fontSize: '100%'}}
                                onClick={handleShowBugReportModal}>
                            <FontAwesomeIcon icon={['fas', 'bug']}/> {t('common:btn_report_bug')}
                        </button>
                    </div>
                </div>
            </div>

            <div className="d-none d-md-block col p-0">
                <div
                    className="w-100 d-flex justify-content-center align-items-center text-white right-card flex-column"
                    style={{background: theme.pages.join.bg_color}}>
                    <img alt="Mundi Game Experiences logo" src={theme.logos.join_page_secondary_logo} className="logo"/>
                </div>
            </div>

            {showBugReportModal && (<BugReportModal onClose={handleCloseBugReportModal}/>)}

            {showConfirmExistingPlayerModal && (
                <ConfirmExistingPlayerModal
                    nickname={chosenPlayerData.nickname}
                    team={chosenPlayerData.team}
                    onClose={handleCloseShowConfirmExistingPlayerModal}/>)}
        </div>
    );
}