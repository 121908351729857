import React, {useMemo} from "react";
import i18nUtil from "utils/i18nUtil";

import "./TournamentRanking.scss";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {GAMES} from "../../../../../../../config/CONSTANTS";
import store from "../../../../../../../redux/store";

export default function TournamentRanking({data}) {
    const {t} = useTranslation('pages/common/board/challenges', {keyPrefix: 'components.tournament_ranking.texts'});

    const isEsg = useSelector(state => state.game.gameInfo.game === GAMES.ESG);
    const showBoardForTeam = store.getState().session.showBoardForTeam;
    const ranking = useSelector(state => state.game.ranking[showBoardForTeam]);

    const rankingFormatted = useMemo(() => {
        return {
            revenue: isEsg
                ? i18nUtil.formatDecimal(ranking?.revenue ?? 0)
                : i18nUtil.formatMoney(ranking?.revenue ?? 0),
            acceleration: i18nUtil.formatPercent(ranking?.acceleration ?? 0),
            months: ranking?.monthsAdvanced ?? 0
        }
    }, [ranking]);

    return (
        <div className="d-flex h-100 w-100 justify-content-center align-items-center pb-3">
            <div
                className="ranking-player-team d-flex w-100 justify-content-center flex-column align-items-center text-white  border-warning">
                <div className="w-100">
                    <div className="box-head bg-warning text-dark text-uppercase">
                        {t('title')}
                    </div>
                </div>

                <div className="d-flex flex-column justify-content-center align-items-center text-center px-2">
                    {isEsg && (<>
                        <h5 className="mb-2 mt-2">
                            {isEsg && t('line_1_esg')}
                        </h5>

                        <h4 className="mb-4 font-weight-bold text-warning">
                            {rankingFormatted.revenue} {t('line_2_esg')}
                        </h4>
                    </>)}

                    {!isEsg && (<>
                        <h5 className="mb-2 mt-2">
                            {t('line_1', rankingFormatted)}
                        </h5>
                    </>)}


                    <h5>{t('placement_info')}</h5>

                    <h1 className="font-weight-bold fa-5x text-warning my-3">
                        {i18nUtil.ordinalSuffix(data.ranking)}
                    </h1>

                    <h5>{t('placement')}</h5>

                    <p className="my-3">{t('replay_info')}</p>
                </div>
            </div>
        </div>
    );
}