import React, {useMemo, useState} from "react";
import {Portal} from "react-portal";

import "./TutorialModal.scss";
import Navigation from "./components/Navigation";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import useTranslationForGame from "hooks/useTranslationForGame";


export default function TutorialModal({onClose}) {
    const {t} = useTranslation('pages/tournament/tutorial_modal');
    const tfg = useTranslationForGame(t);

    const isMobile = useSelector(state => state.session.isMobile);
    const [step, setStep] = useState(1);

    const stepsData = useMemo(() => {
        return tfg(`steps`, {returnObjects: true});
    }, [step, t, tfg]);

    const totalSteps = useMemo(() => {
        return Object.keys(stepsData).length;
    }, [stepsData]);

    const handleNextStep = () => {
        if (step < totalSteps)
            setStep(step + 1);
    };

    const handlePreviousStep = () => {
        if (step > 1)
            setStep(step - 1);
    }

    return (<Portal>
        <div id="first-access-modal"
             className="animate__animated animate__fadeIn px-4 py-4 d-flex justify-content-center">

            <div className="text-white text-center d-flex flex-column" style={{maxWidth: 1000, maxHeight: 1000}}>

                <div className="mt-1 pb-4" hidden={!isMobile}>
                    <Navigation current={step}
                                total={totalSteps}
                                onClose={onClose}
                                onPrevious={handlePreviousStep}
                                onNext={handleNextStep}/>
                </div>

                <div className="flex-fill pb-5 mb-5 pb-md-0 mb-md-0 d-flex align-items-center">
                    <div className="animate__animated animate__fadeIn" key={step}>
                        <div key={step} className="animate__animated animate__fadeIn animate__faster content">
                            <h4 className="text-warning font-weight-bold mb-5">
                                {stepsData?.[step - 1]?.title}
                            </h4>

                            {stepsData?.[step - 1]?.messages?.map(message => (
                                <h5 key={message}>{message}</h5>
                            ))}

                            {step === totalSteps && (
                                <button className="btn btn-success shadow rounded text-uppercase mt-3"
                                        onClick={onClose}>
                                    {t('buttons.start')}
                                </button>
                            )}
                        </div>
                    </div>
                </div>


                <div className="mt-4 pb-5" hidden={isMobile}>
                    <Navigation current={step}
                                total={totalSteps}
                                onClose={onClose}
                                onPrevious={handlePreviousStep}
                                onNext={handleNextStep}/>
                </div>
            </div>
        </div>
    </Portal>);
}

