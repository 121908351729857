import React, {useState} from "react";
import store from "../../../../redux/store";
import AdvanceButtons from "../components/AdvanceButtons";
import {useSelector} from "react-redux";
import ToastUtil from "../../../../utils/ToastUtil";
import {useTranslation} from "react-i18next";

const DEFAULT_DATA = {
    name: '',
    position: '',
}

export default function CertificateStep({data, onAdvance, onBack, isLastStep}) {
    const {t} = useTranslation('pages/tournament/first_access_modal');

    const canRequestCertificate = useSelector(state => state.game.tournamentInfo.canRequestCertificate);

    const [certificate, setCertificate] = useState({...DEFAULT_DATA, ...(data ?? {})});

    const buildData = (ignoreErrors = false) => {
        const playerData = store.getState().session.user;
        const position = certificate.position.trim();

        const data = {
            name: certificate.name.trim(),
            email: playerData.email
        };

        if (!!position === true)
            data.position = position;


        if (!!data.name !== true && !ignoreErrors) {
            setCertificate({
                name: data.name,
                position: data.position ?? ''
            });

            ToastUtil.toastDanger(t('toasts.check_form.title'));

            return false;
        } else {
            return {certificate: data};
        }
    }

    const handleSubmit = ev => {
        ev.preventDefault();

        if (!canRequestCertificate) {
            onAdvance({});
            return;
        }

        const data = buildData();

        if (data)
            onAdvance(data);
    }

    const handleBack = () => {
        onBack(buildData(true));
    }

    const handleUpdateField = (field, ev) => {
        setCertificate({
            ...certificate,
            [field]: ev.target.value
        });
    }

    return (<form onSubmit={handleSubmit} className="animate__animated animate__fadeIn">

        {canRequestCertificate && (<>
            <h4 className="mb-5 text-warning">{t('texts.certificate_step.title')}</h4>

            <p className="mb-1">{t('texts.certificate_step.fill_info')}</p>
            <p className="mb-4 text-info">{t('texts.certificate_step.play_info')}</p>

            <div className="row">
                <div className="col-12 col-md-6 mb-2">
                    <input value={certificate.name} name="name" className="form-control" type="text"
                           autoFocus={true}
                           placeholder={t('inputs.name.label')}
                           onChange={ev => handleUpdateField('name', ev)}
                           required/>
                </div>

                <div className="col-12 col-md-6 mb-2">
                    <input value={certificate.position} name="position" className="form-control" type="text"
                           onChange={ev => handleUpdateField('position', ev)}
                           placeholder={t('inputs.position.label')}/>
                </div>
            </div>
        </>)}

        {!canRequestCertificate && (<>
            <h4 className="mb-5 text-warning">{t('texts.certificate_step.not_first_session_line_1')}</h4>
            <h5 className="mb-3">{t('texts.certificate_step.not_first_session_line_2')}</h5>
            <h5 className="mb-3">{t('texts.certificate_step.not_first_session_line_3')}  </h5>
            <h5 className="mb-5">{t('texts.certificate_step.not_first_session_line_4')}</h5>
        </>)}

        <AdvanceButtons onBack={handleBack} isLastStep={isLastStep} isFirstStep={false}/>
    </form>);
}