import React, {useMemo} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import i18nUtil from "../../../../utils/i18nUtil";

export default function SelectedItemBadge({onRemove, data}) {
    const handleClick = () => {
        onRemove(data);
    }

    const translatedTitle = useMemo(() => i18nUtil.translateForTournamentObj(data, 'title'), []);

    return (<button className="btn btn-secondary font-weight-bold px-2 py-1 selected-item-badge" onClick={handleClick}>
        <FontAwesomeIcon icon={['far', 'minus-square']} className="text-danger mr-1"/>
        {translatedTitle}
    </button>)
}