import React, {useState} from "react";
import AdvanceButtons from "../components/AdvanceButtons";
import ToastUtil from "../../../../utils/ToastUtil";
import {useTranslation} from "react-i18next";

export default function NicknameStep({data, onAdvance, onBack, isLastStep}) {
    const {t} = useTranslation('pages/tournament/first_access_modal');
    const [nickname, setNickname] = useState(data ?? '');

    const buildData = (ignoreErrors = false) => {
        const data = {nickname: nickname.trim()};

        if (!ignoreErrors && (data.nickname.length < 4 || data.nickname > 20)) {
            setNickname(data.nickname)
            ToastUtil.toastDanger(t('toasts.check_form.title'));

            return false;
        }


        return {player: {nickname}};
    }

    const handleSubmit = ev => {
        ev.preventDefault();

        const data = buildData();

        if (data)
            onAdvance(data);
    }


    const handleBack = () => {
        onBack(buildData(true));
    }

    return (<form onSubmit={handleSubmit} className="animate__animated animate__fadeIn">
        <h4 className="mb-5 text-warning">{t('texts.nickname_step.title')}</h4>

        <h5>{t('texts.nickname_step.choose_nickname')}</h5>
        <p>{t('texts.nickname_step.nickname_info')}</p>

        <div className="form-group">
            <label className="required">{t('inputs.nickname.label')}</label>

            <input name="nickname"
                   style={{textAlign: 'center'}}
                   value={nickname}
                   className="form-control"
                   type="text"
                   minLength={3}
                   maxLength={20}
                   onChange={ev => setNickname(ev.target.value)}
                   required autoFocus/>

            <p className="form-text small">{t('inputs.nickname.tip')}</p>
        </div>


        <AdvanceButtons onBack={handleBack} isLastStep={isLastStep} isFirstStep={true}/>
    </form>);
}