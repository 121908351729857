import React, {memo, useMemo, useRef} from "react";
import GameCardLayout from "components/cards/layouts/GameCardLayout";
import SimpleBar from "simplebar-react";

import "./QuestionCard.scss";
import monthsForwardCorrectAnswerIcon from "assets/img/board/ic_month_correct_challange.svg";
import investmentCorrectAnswerIcon from "assets/img/board/ic_investment_correct_challange.svg";
import monthsForwardWrongAnswerIcon from "assets/img/board/ic_month_wrong_challange.svg";
import investmentWrongAnswerIcon from "assets/img/board/ic_investment_wrong_challange.svg";
import I18nUtil from "utils/i18nUtil";
import i18nUtil from "utils/i18nUtil";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import Utils from "utils/Utils";
import {useSelector} from "react-redux";

import answerCorrectIcon from "assets/img/board/ic_ans_correct_big.svg";
import answerIncorrectIcon from "assets/img/board/ic_ans_wrong_big.svg";


const QuestionCard = ({
                          questionCard,
                          answarable = false,
                          selectedOptions = [],
                          submittedOptions = [],
                          onSelectedOptionsUpdate = false,
                          onSubmit = false,
                          // shows correct answers
                          showResult = false,
                          // shows wrong/right icons
                          showOutcomeIcon = true,
                          showOutcome = false,
                          isDesignatedToATeam = true,
                          isLoading = false,
                          isCorrect = false,
                          showSubmitButton = false
                          //  designatedQuestion, isLoading, onSelectedOptionsUpdate, isCorrect
                      }) => {

    const {t} = useTranslation('components/cards', {keyPrefix: "question_card"});

    const isDemonstration = useSelector(state => state.game.isDemonstration);
    const isTournament = useSelector(state => state.game.isTournament);

    const scrollWrapperRef = useRef();

    const {id, step, order, concept, question, answers} = useMemo(() => questionCard, [questionCard]);
    const {monthsRightAnswer, monthsWrongAnswer} = useMemo(() => questionCard.getMonthsConfig(), [questionCard]);
    const {
        investmentRightAnswer,
        investmentWrongAnswer
    } = useMemo(() => questionCard.getInvestmentConfig(), [questionCard]);

    const handleGenerateAnswerIcon = (answer) => {
        if (!answer.isCorrect && !selectedOptions.includes(answer.order)) return '';

        if (!showOutcome && selectedOptions.length === 0) {
            if (answer.isCorrect) {
                return (<FontAwesomeIcon icon={['fas', 'check-circle']} className="text-success"/>);
            } else {
                return '';
            }
        }

        if ((answer.isCorrect && selectedOptions.includes(answer.order))
            || (!answer.isCorrect && !selectedOptions.includes(answer.order))) {
            return (<FontAwesomeIcon icon={['fas', 'check-circle']} className="text-success"/>);
        } else {
            return (<FontAwesomeIcon icon={['fas', 'times-circle']} className="text-danger"/>);
        }
    };

    const handleToggleOption = (event, option) => {
        event.preventDefault();

        if (!answarable || showResult) return;

        let res = [...selectedOptions];
        if (!res.includes(option)) res.push(option);
        else res = res.filter(opt => opt !== option);

        if (onSelectedOptionsUpdate) onSelectedOptionsUpdate(res);
    };

    const handleSubmit = (event) => {
        if (!answarable || !showSubmitButton || showResult || !onSubmit) return;
        onSubmit(questionCard, selectedOptions);
    };

    const selectedOptionsInLetters = useMemo(() => {
        if (selectedOptions.length === 0) return t('texts.none');
        return selectedOptions.map(opt => Utils.numberToLetter(opt)).join(', ')
    }, [t, selectedOptions]);

    const submittedOptionsInLetters = useMemo(() => {
        if (submittedOptions.length === 0) return t('texts.none');
        return submittedOptions.map(opt => Utils.numberToLetter(opt)).join(', ')
    }, [t, submittedOptions]);

    const correctOptionsInLetters = useMemo(() => {
        if (!showResult) return t('texts.none');
        return answers.filter(opt => opt.isCorrect).map(opt => Utils.numberToLetter(opt.order)).join(', ')
    }, [t, showResult, answers]);

    const cardClass = useMemo(() => {
        let res = !showResult && answarable ? 'answerable not-selectable ' : '';
        if (showResult && !isLoading && showOutcome) res += isCorrect ? 'correct' : 'incorrect';

        return res;
    }, [showResult, answarable, isLoading, showOutcome, isCorrect]);


    return (
        <GameCardLayout>
            <div key={id}
                 className={"d-flex question-card h-100 " + cardClass}>
                <div className="box border-danger bg-white h-100 w-100 d-flex flex-column question">
                    <div className="question-header-container">
                        <div className="box-head bg-danger text-white">
                            {t('texts.title', {step: i18nUtil.stepName(step), concept})}
                        </div>

                        <div className="box-head-right bg-danger text-white">{order}</div>
                    </div>

                    {(showSubmitButton || !isLoading) && (
                        <div className="value-content flex-fill" autoFocus={true}>
                            <SimpleBar autoHide={false} className="content-scrollable" ref={scrollWrapperRef}
                                       tabIndex={-1}>
                                <h5 className="font-weight-bold mb-3">{question}</h5>

                                {showResult && (
                                    <p className="mb-0">
                                        {t('texts.correct_answer')}
                                        <span className="font-weight-bold ml-1">
                                        {correctOptionsInLetters}
                                    </span>
                                    </p>
                                )}

                                {isDesignatedToATeam && (showOutcome || answarable) && (<>
                                    <p>
                                        {t('texts.submitted_answer')}
                                        <span className="font-weight-bold ml-1">
                                            {submittedOptionsInLetters}
                                        </span>
                                    </p>


                                    {showOutcomeIcon && showOutcome && showResult && (<>
                                        <div
                                            className={`py-2 px-3 rounded d-flex justify-content-center align-items-center outcome-container animate__animated animate__fadeIn animate__fast`}>

                                            <img src={isCorrect ? answerCorrectIcon : answerIncorrectIcon} height={30}/>

                                            <p className="mb-0 ml-2 text-white font-weight-bold text-uppercase">
                                                {isCorrect ? t('texts.correct') : t('texts.incorrect')}
                                            </p>
                                        </div>

                                        <hr/>
                                    </>)}
                                </>)}

                                {!isDesignatedToATeam && showOutcome && selectedOptions.length > 0 && (
                                    <p>
                                        {t('texts.selected_answer')}
                                        <span className="font-weight-bold ml-1">
                                            {selectedOptionsInLetters}
                                        </span>
                                    </p>
                                )}

                                <table className="w-100 mt-2">
                                    <tbody>
                                    {answers.map(answer => {
                                        return (
                                            <tr key={id + answer.order}
                                                onClick={(ev) => handleToggleOption(ev, answer.order)}
                                                className={submittedOptions.includes(answer.order) ? 'selected' : ''}>
                                                <td className={showResult && answer.isCorrect ? 'font-weight-bold' : ''}>
                                                    {Utils.numberToLetter(answer.order).toLowerCase()}) {answer.description}
                                                </td>

                                                <td>
                                                    {!showResult && answarable && (
                                                        <label className="container">
                                                            <input type="checkbox" disabled={isLoading}
                                                                   checked={selectedOptions.includes(answer.order)}
                                                                   onChange={(ev) => handleToggleOption(ev, answer.order)}/>
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    )}

                                                    {showResult && handleGenerateAnswerIcon(answer)}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </table>
                            </SimpleBar>
                        </div>
                    )}

                    {!showSubmitButton && isLoading && (
                        <div className="value-content flex-fill pb-3">
                            <div
                                className="loading-indicator w-100 h-100 d-flex justify-content-center align-items-center text-danger">
                                <FontAwesomeIcon icon={['fas', 'circle-notch']} className="fa-6x fa-spin"/>
                            </div>
                        </div>
                    )}


                    <div className="answer-progress">
                        <div className="row">
                            <div
                                className="col-6 d-flex justify-content-center border-right border-danger answer-correct">
                                <div className="answer-progress-result d-flex align-items-center mr-3">
                                    <img src={monthsForwardCorrectAnswerIcon}/>
                                    <p className="d-inline-block">{t('texts.month', {count: monthsRightAnswer})}</p>
                                </div>

                                <div className="answer-progress-result d-flex align-items-center">
                                    <img src={investmentCorrectAnswerIcon}/>
                                    <p className="d-inline-block">{I18nUtil.formatMoney(investmentRightAnswer)}</p>
                                </div>
                            </div>

                            <div className="col-6 d-flex justify-content-center answer-incorrect">
                                <div className="answer-progress-result d-flex align-items-center mr-3">
                                    <img src={monthsForwardWrongAnswerIcon}/>
                                    <p className="d-inline-block">{t('texts.month', {count: monthsWrongAnswer})}</p>
                                </div>

                                <div className="answer-progress-result d-flex align-items-center">
                                    <img src={investmentWrongAnswerIcon}/>
                                    <p className="d-inline-block">{I18nUtil.formatMoney(investmentWrongAnswer)}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {showSubmitButton && !showResult && answarable && (
                        <div className="box-footer bg-danger">
                            {isLoading && <FontAwesomeIcon icon={['fas', 'circle-notch']} className="fa-spin"
                                                           style={{fontSize: '1.9rem'}}/>}
                            {!isLoading && (
                                <button type="button" className="w-100 h-100 bg-transparent border-0 text-uppercase"
                                        onClick={handleSubmit}>

                                    {(isDemonstration || isTournament) && t('buttons.reveal')}
                                    {!isDemonstration && !isTournament && (submittedOptions.length ? t('buttons.change') : t('buttons.submit'))}
                                </button>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </GameCardLayout>
    );
}

export default memo(QuestionCard);