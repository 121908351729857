import React from "react";
import {Button, Modal} from "react-bootstrap";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import MobileModalFooter from "../../../../../components/MobileModalFooter";

export default function TournamentPlayerInfoModal({onClose}) {
    const {t} = useTranslation('pages/tournament/player_info_modal');

    const tournamentInfo = useSelector(state => state.game.tournamentInfo);
    const player = useSelector(state => state.session.user);
    const isMobile = useSelector(state => state.session.isMobile);

    return (
        <Modal show={true} onHide={onClose} size={isMobile ? 'xl' : 'md'}>
            <Modal.Header closeButton>
                <Modal.Title>{t('texts.title')}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <h5 className="mb-3">{t('texts.playing_as')}</h5>

                <div className="row">
                    <div className="form-group col-12 col-md-6">
                        <label>{t('texts.nickname')}</label>
                        <p>{player.nickname ?? '-'}</p>
                    </div>

                    <div className="form-group col-12 col-md-6">
                        <label>{t('texts.email')}</label>
                        <p>{player.email ?? '-'}</p>
                    </div>
                </div>

                <hr/>

                {tournamentInfo.canRequestCertificate && (<>
                    <h6 className="text-success font-weight-bold">{t('texts.certificate_info')}</h6>
                </>)}

                {!tournamentInfo.canRequestCertificate && (<>
                    <h6 className="text-info">{t('texts.no_certificate_info')}</h6>
                </>)}

            </Modal.Body>

            {isMobile && (<MobileModalFooter onClose={onClose}/>)}

            {!isMobile && (
                <Modal.Footer>
                    <Button variant="secondary" onClick={onClose}>{t('buttons.close')}</Button>
                </Modal.Footer>
            )}
        </Modal>
    )
}