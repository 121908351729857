import React, {useMemo} from "react";

import "./UnpredictabilityCard.scss";
import neutralIcon from "assets/img/board/ic_unpredict_neutral.svg";
import positiveIcon from "assets/img/board/ic_unpredict_positive.svg";
import negativeIcon from "assets/img/board/ic_unpredict_negative.svg";

import GameCardLayout from "components/cards/layouts/GameCardLayout";
import SimpleBar from "simplebar-react";
import {UNPREDICTABILITY_IMPACT} from "config/CONSTANTS";
import Utils from "utils/Utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {
    translateForTournament
} from "../../pages/common/Board/components/Challenges/services/TournamentChallengesComponentService";

export const UNPREDICTABILITY_CARD_LAYOUT = {
    NO_CONSEQUENCE: 0,
    CONSEQUENCES_ON_RIGHT_SIDE: 1,
    CONSEQUENCES_BELLOW: 2,
}

export default function UnpredictabilityCard({
                                                 children,
                                                 unpredictabilityCard,
                                                 layout = UNPREDICTABILITY_CARD_LAYOUT.NO_CONSEQUENCE,
                                                 cardColor = 'info',
                                                 reveal = false,
                                                 revealOutcome = false,
                                                 impact = UNPREDICTABILITY_IMPACT.NEUTRAL,
                                                 isLoading = false
                                             }) {

    const {t} = useTranslation('components/cards', {keyPrefix: 'unpredictability_card.texts'});

    const isTournament = useSelector(state => state.game.isTournament);
    const currentStep = useSelector(state => state.game.currentStep);

    const icon = useMemo(() => {
        switch (impact) {
            case UNPREDICTABILITY_IMPACT.POSITIVE:
                return positiveIcon;
            case UNPREDICTABILITY_IMPACT.NEGATIVE:
                return negativeIcon;
            default:
                return neutralIcon;
        }
    }, [impact]);

    const consequencesContainerClass = useMemo(() => {
        if (layout === UNPREDICTABILITY_CARD_LAYOUT.CONSEQUENCES_BELLOW) {
            return 'py-2 px-1 mt-3 w-100 text-center animate__animated animate__faster animate__slideInUp';
        } else if (layout === UNPREDICTABILITY_CARD_LAYOUT.CONSEQUENCES_ON_RIGHT_SIDE) {
            return 'py-2 px-1 ml-3 w-auto animate__animated animate__faster animate__slideInRight';
        }

        return '';
    }, [layout]);

    const cardClass = useMemo(() => {
        if (layout === UNPREDICTABILITY_CARD_LAYOUT.CONSEQUENCES_BELLOW) {
            return 'flex-column justify-content-center';
        }

        return '';
    }, [layout]);

    const message = useMemo(() => {
        return isTournament
            ? translateForTournament(`unpredictability_messages.${currentStep}.description`)
            : '';
    }, []);

    return (
        <div className={"unpredictability-card text-white d-flex align-items-center " + cardClass}>
            <GameCardLayout>
                <div className={"box bg-white pb-2 border-" + cardColor}>
                    <div className={"box-head text-uppercase bg-" + cardColor}>{t('title')}</div>
                    <span className="clearfix"/>

                    <div
                        className="value-content h-75 text-center d-flex justify-content-center align-items-center">

                        <SimpleBar className="content-scrollable px-1 w-100">
                            <>
                                {isLoading && (
                                    <FontAwesomeIcon icon={['fas', 'circle-notch']}
                                                     className={"fa-4x fa-spin text-" + cardColor}/>)}

                                {!isLoading && !reveal && (
                                    <FontAwesomeIcon icon={['fas', 'question-circle']}
                                                     className={"fa-4x text-" + cardColor}/>
                                )}

                                {!isLoading && reveal && (
                                    <span
                                        className="mb-3 text-dark">{unpredictabilityCard?.event || ''}</span>)}

                            </>
                        </SimpleBar>
                    </div>
                    {reveal && revealOutcome &&
                        (<img alt="Unpredictability icon" src={icon}
                              className="unpredictability-icon animate__animated animate__faster animate__zoomIn"/>)
                    }
                </div>
            </GameCardLayout>

            {children}


            {layout !== UNPREDICTABILITY_CARD_LAYOUT.NO_CONSEQUENCE && reveal && (
                <div className={"rounded bg-dark " + consequencesContainerClass}>
                    <SimpleBar className="content-scrollable-consequences px-1">
                        {!revealOutcome && (<>
                            {Utils.isset(unpredictabilityCard.positiveConsequenceDescription) && (
                                <>
                                    <b>{t('positive_consequence')}</b>
                                    <p dangerouslySetInnerHTML={{__html: unpredictabilityCard.positiveConsequenceDescription || '-'}}/>
                                </>
                            )}

                            {Utils.isset(unpredictabilityCard.negativeConsequenceDescription) && (
                                <>
                                    <b>{t('negative_consequence')}</b>
                                    <p dangerouslySetInnerHTML={{__html: unpredictabilityCard.negativeConsequenceDescription || '-'}}/>
                                </>
                            )}

                            {Utils.isset(unpredictabilityCard.neutralConsequenceDescription) && (
                                <>
                                    <b>{t('consequence')}</b>
                                    <p dangerouslySetInnerHTML={{__html: unpredictabilityCard.neutralConsequenceDescription || '-'}}/>
                                </>
                            )}
                        </>)}

                        {revealOutcome && (<>
                            <b className="mt-1 d-block">{t('outcome')}</b>
                            {impact === UNPREDICTABILITY_IMPACT.POSITIVE &&
                                <p dangerouslySetInnerHTML={{__html: unpredictabilityCard?.positiveConsequenceDescription || '-'}}/>
                            }

                            {impact === UNPREDICTABILITY_IMPACT.NEGATIVE &&
                                <p dangerouslySetInnerHTML={{__html: unpredictabilityCard?.negativeConsequenceDescription || '-'}}/>
                            }

                            {impact === UNPREDICTABILITY_IMPACT.NEUTRAL &&
                                <p dangerouslySetInnerHTML={{__html: unpredictabilityCard?.neutralConsequenceDescription || '-'}}/>
                            }
                        </>)}
                    </SimpleBar>
                </div>
            )}

            {isTournament && !reveal && (
                <div className={"rounded bg-dark " + consequencesContainerClass}>
                    <SimpleBar className="content-scrollable-consequences px-1">
                        <p className="m-0">{message}</p>
                    </SimpleBar>
                </div>
            )}
        </div>
    );
}