import React, {useEffect, useMemo, useState} from "react";
import {Modal} from "react-bootstrap";

import "./TournamentPitchModal.scss";
import logo from "assets/img/logos/svg/logo_only.svg";
import {useSelector} from "react-redux";
import Item from "./components/Item";
import i18nUtil from "utils/i18nUtil";
import SelectedItemBadge from "./components/SelectedItemBadge";
import AudioService from "services/common/AudioService";
import {SOUND_EFFECTS} from "config/AUDIO_FILES";
import ButtonSpinner from "components/ButtonSpinner";
import TournamentService from "services/common/TournamentService";

import TOURNAMENT_CONFIG from "config/TOURNAMENT_CONFIG";
import MobileModalFooter from "components/MobileModalFooter";
import {useTranslation} from "react-i18next";
import GameUtil from "utils/GameUtil";
import {
    translateForTournament
} from "../../common/Board/components/Challenges/services/TournamentChallengesComponentService";
import useTranslationForGame from "hooks/useTranslationForGame";

const TOTAL_OPTIONS_TO_SELECT = 5;

export default function TournamentPitchModal({onClose}) {
    const {t} = useTranslation('pages/tournament/pitch_modal');
    const tfg = useTranslationForGame(t);

    const isMobile = useSelector(state => state.session.isMobile);
    const currentStep = useSelector(state => state.game.currentStep);
    const pitchData = useSelector(state => state.teams[TOURNAMENT_CONFIG.TEAM].pitchData?.[currentStep]);

    const [selectedOptions, setSelectedOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [revealOutcome, setRevealOutcome] = useState(!!pitchData);

    const selectedOptionsIds = useMemo(() => {
        return selectedOptions.reduce((prev, curr) => {
            prev[curr.id] = true;

            return prev;
        }, {});
    }, [selectedOptions]);

    const options = useMemo(() => {
        const newOptions = GameUtil.getTournamentData().pitch_data;

        return newOptions[currentStep];
    }, []);

    const optionsToShow = useMemo(() => {
        if (selectedOptions.length === TOTAL_OPTIONS_TO_SELECT) {
            setTimeout(() => {
                document.querySelector('.pitch-modal .btn-top')?.click();
            }, 250);

            return selectedOptions;
        }

        return options.sort((a, b) => a.title.localeCompare(b.title));
    }, [selectedOptions]);

    const handleToggle = (option) => {
        if (isLoading)
            return;

        let newOptions = [...selectedOptions];

        if (selectedOptionsIds[option.id])
            newOptions = newOptions.filter(c => c.id !== option.id);
        else
            newOptions.push(option)

        setSelectedOptions(newOptions);
    }

    const handleReveal = async () => {
        setIsLoading(true);

        const bonus = Math.max(selectedOptions.reduce((prev, curr) => prev + curr.points, 0), 0);

        const params = {
            step: currentStep,
            data: {
                selectedOptions: selectedOptions.map(x => x.id),
                bonus
            }
        }

        const revealEffectPromise = AudioService.play(SOUND_EFFECTS.QUESTIONS.DRUM_ROLL, 250);
        const updateAnswerPromise = TournamentService.updatePitchData(params);
        await Promise.all([revealEffectPromise, updateAnswerPromise]);


        const resultAudioToPlay = bonus > 0
            ? SOUND_EFFECTS.ACCOUNTING.INVESTMENT_RECEIVED
            : SOUND_EFFECTS.QUESTIONS.WRONG_ANSWER;

        setIsLoading(false);

        AudioService.play(resultAudioToPlay);
    }

    const handleContinueGame = () => {
        setIsLoading(true);
        TournamentService.advanceGame().finally(() => setIsLoading(false));
    }

    useEffect(() => {
        if (!!pitchData && !isLoading) {
            setRevealOutcome(true);
            setSelectedOptions(options.filter(x => pitchData.selectedOptions.includes(x.id)));
        }
    }, [isLoading, pitchData]);


    const subtitle = useMemo(() => translateForTournament(`pitch.${currentStep}.message_summary`), []);

    return (
        <Modal show={true} size={'xl'} onHide={onClose} className={"pitch-modal"} scrollable={!isMobile}>

            <Modal.Header closeButton className={`bg-dark text-white`}>
                <div className="modal-header-content w-100 d-flex flex-column flex-md-row align-items-md-center">
                    <img alt="Mundi Game Experiences logo" src={logo} className="mr-4 d-none d-md-inline-block"/>

                    <div>
                        <Modal.Title>{i18nUtil.stepName(currentStep)}</Modal.Title>
                        <p className="mb-0 mt-1 small">{subtitle}</p>
                    </div>

                </div>
            </Modal.Header>

            <Modal.Body>
                {!revealOutcome && (
                    <div
                        className="w-100 p-2 text-white bg-primary rounded d-flex flex-column flex-md-row align-items-center justify-content-between mb-4">

                        <p className="small text-center mr-3 mb-2 mb-md-0">
                            {t('texts.selected_count', {amount: selectedOptions.length, max: TOTAL_OPTIONS_TO_SELECT})}
                        </p>

                        <div style={{gap: 5}}
                             className="selected-items-container d-flex flex-wrap justify-content-center align-content-center">

                            {selectedOptions.map((option) => (<SelectedItemBadge
                                key={option.id}
                                data={option}
                                onRemove={handleToggle}
                            />))}


                            {selectedOptions.length === 0 && (
                                <span className="text-muted">{t('texts.none')}</span>
                            )}
                        </div>
                    </div>
                )}

                {!revealOutcome && selectedOptions.length === TOTAL_OPTIONS_TO_SELECT && (
                    <div className="w-100 text-center mb-5 pt-2 animate__animated animate__fadeInDown animate__faster">
                        <ButtonSpinner className="btn btn-success rounded" onClick={handleReveal}
                                       showAnimation={isLoading}>
                            {t('buttons.confirm')}
                        </ButtonSpinner>
                    </div>
                )}

                {revealOutcome && (<>
                    <div className="w-100 text-center mb-5 pt-3 animate__animated animate__fadeInDown animate__faster">
                        <h5>{t('texts.bonus')}</h5>
                        <h3 className="text-danger font-weight-bold">{pitchData.bonus}%</h3>
                        <h5>{tfg('texts.bonus_type')}</h5>


                        <ButtonSpinner className="btn btn-primary btn-sm mt-3"
                                       showAnimation={isLoading}
                                       onClick={handleContinueGame}>

                            {t('buttons.continue')}
                        </ButtonSpinner>

                        <hr/>
                    </div>

                    <h5 className="mb-3 text-center">{t('texts.selected_options')}</h5>
                </>)}


                <div className="d-flex justify-content-center align-items-stretch flex-wrap">
                    {optionsToShow.map((option) => (<Item
                        key={option.id}
                        isSelected={!!selectedOptionsIds[option.id]}
                        revealOutcome={revealOutcome}
                        onToggle={handleToggle}
                        data={option}
                    />))}
                </div>
            </Modal.Body>

            {isMobile && (<MobileModalFooter onClose={onClose} scrollContainer={'.pitch-modal'}/>)}
        </Modal>
    );
}