import React, {useMemo, useState} from "react";
import {Portal} from "react-portal";

import "./FirstAccessModal.scss";
import NicknameStep from "./steps/NicknameStep";
import CertificateStep from "./steps/CertificateStep";
import ToastUtil from "utils/ToastUtil";
import OverlayUtil from "utils/OverlayUtil";
import PlayerService from "services/common/PlayerService";
import store from "redux/store";
import SwalModalUtil from "utils/SwalModalUtil";
import SessionPersistenceUtil, {PLAYER_KEY} from "utils/SessionPersistenceUtil";
import AuthService from "services/player/AuthService";
import {useTranslation} from "react-i18next";

const LAST_STEP = 2;

export default function FirstAccessModal({onClose}) {
    const {t} = useTranslation('pages/tournament/first_access_modal');

    const [data, setData] = useState({});
    const [step, setStep] = useState(1);

    const handleUpdateData = (newData) => {
        setData({...data, ...newData});
    }
    
    const handleAdvance = (newData) => {
        handleUpdateData(newData);

        if (step < LAST_STEP) {
            setStep(step + 1);
        } else {
            OverlayUtil.toggleLoadingOverlay(true, t('overlays.initializing_session'));

            const playerId = store.getState().session.user.id;
            const params = {...data, ...newData};

            PlayerService.updateData(playerId, playerId, params).then(result => {
                if (result)
                    onClose();
                else
                    ToastUtil.toastDanger(t('toasts.initialize_error.title'));
            }).finally(() => OverlayUtil.hide());
        }
    }

    const handleBack = (newData) => {
        if (step === 0)
            return;

        handleUpdateData(newData);
        setStep(step - 1);
    }

    const handleQuit = () => {
        SwalModalUtil.confirmModal(
            t('confirms.quit.title'),
            t('confirms.quit.message'),
            t('confirms.quit.confirm'),
            t('confirms.quit.cancel'),
        ).then(result => {
            if (result) {
                const gameLink = SessionPersistenceUtil.get(PLAYER_KEY).gameUrl;

                AuthService.leave();
                window.location.replace(gameLink);
            }
        })
    }


    const formStep = useMemo(() => {
        if (step === 1)
            return <NicknameStep data={data?.player?.nickname}
                                 onAdvance={handleAdvance}
                                 onBack={handleBack}
                                 isLastStep={false}/>;

        return <CertificateStep data={data?.certificate}
                                onAdvance={handleAdvance}
                                onBack={handleBack}
                                isLastStep={true}/>;
    }, [step]);

    return (<Portal>
        <div id="first-access-modal" className="animate__animated animate__fadeIn text-center">
            <div
                className="p-4 pb-2 text-white d-flex flex-column justify-content-center align-items-center h-100 w-100">
                <div className="text-center">
                    <div className="pt-3" style={{maxWidth: 1000}}>
                        {formStep}
                    </div>

                    <button onClick={handleQuit} className="btn btn-sm btn-outline-secondary border-0 rounded mt-5 ">
                        {t('buttons.quit')}
                    </button>
                </div>
            </div>
        </div>
    </Portal>);
}

