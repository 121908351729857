import React, {memo, useEffect, useMemo, useState} from "react";

import "./QuestionChallenge.scss";
import TeamService from "services/player/TeamService";
import ToastUtil from "utils/ToastUtil";
import {useSelector} from "react-redux";
import store from "redux/store";
import LoggerService from "services/common/LoggerService";
import {useTranslation} from "react-i18next";
import QuestionCard from "components/cards/QuestionCard";
import DemoSessionService from "services/common/DemoSessionService";
import AudioService from "services/common/AudioService";
import {SOUND_EFFECTS} from "config/AUDIO_FILES";
import CardUtil from "utils/CardUtil";
import GameService from "services/gameMaster/GameService";
import {updateQuestion} from "redux/slices/teamsSlice";
import {updateDemoState} from "redux/slices/demoSlice";
import TournamentService from "services/common/TournamentService";
import QuestionExplanationCard from "components/cards/QuestionExplanationCard";
import QuestionChallengeButtons from "./QuestionChallengeButtons";

const QuestionChallenge = ({data}) => {
    const {card, isCorrect, showResult, step, answer} = data;

    const {t} = useTranslation('pages/common/board/challenges', {keyPrefix: 'components.question_challenge'});
    const t2 = useTranslation('common').t;

    const isDemonstration = useSelector(state => state.game.isDemonstration);
    const isTournament = useSelector(state => state.game.isTournament);
    const isMobile = useSelector(state => state.session.isMobile);

    const revealDemoQuestion = useSelector(state => state.demo.state.revealQuestion);
    const revealedDemoQuestion = useSelector(state => state.demo.state.revealedQuestion);
    const isGameMaster = useSelector(state => state.session.isGameMaster);

    const [selectedOptions, setSelectedOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showSubmitButton, setShowSubmitButton] = useState(true);
    const [isAnswerable, setIsAnswerable] = useState(true);
    const [showExplanation, setShowExplanation] = useState(false);

    const elClass = useMemo(() => {
        if (isDemonstration && revealedDemoQuestion)
            return 'demo-question';

        if (isTournament)
            return showSubmitButton ? 'tournament-question' : 'tournament-question-revealed';

        return '';
    }, [isTournament, isDemonstration, revealedDemoQuestion, showSubmitButton]);

    const handleAdvanceGame = async () => {
        setIsLoading(true);
        if (!await TournamentService.advanceGame()) {
            setIsLoading(false);

            ToastUtil.toastDanger(
                t2('toasts.advance_game_error.title'),
                t2('toasts.advance_game_error.message')
            );
        }

        setIsLoading(false);
    }

    const handleToggleShowExplanation = () => {
        setShowExplanation(oldVal => !oldVal);
    }

    const handleRevealDemoQuestion = async (answers) => {
        DemoSessionService.toggleChallengesPagination(false);
        DemoSessionService.setQuestionAnswer(answers);

        setShowSubmitButton(false);
        setIsLoading(true);

        await AudioService.play(SOUND_EFFECTS.QUESTIONS.DRUM_ROLL, 250);
        setIsLoading(false);

        const isCorrect = DemoSessionService.isQuestionCorrect();
        const resultAudioToPlay = isCorrect ? SOUND_EFFECTS.QUESTIONS.RIGHT_ANSWER : SOUND_EFFECTS.QUESTIONS.WRONG_ANSWER;

        DemoSessionService.setHasRevealedQuestion();
        await AudioService.play(resultAudioToPlay);

        DemoSessionService.playQuestionVideo();
    }

    const handleReviewTournamentQuestion = async (answers) => {
        setShowSubmitButton(false);
        setIsLoading(true);

        store.dispatch(updateQuestion({team: 1, step, data: {answer: answers}}));
        store.dispatch(updateDemoState({revealQuestion: true}));

        const revealEffectPromise = AudioService.play(SOUND_EFFECTS.QUESTIONS.DRUM_ROLL, 250);
        const updateAnswerPromise = TeamService.updateQuestionAnswer({step, answers});
        await Promise.all([revealEffectPromise, updateAnswerPromise]);


        const isCorrect = CardUtil.isAssignedQuestionAnswerCorrect({...data, answer: answers})
        const resultAudioToPlay = isCorrect ? SOUND_EFFECTS.QUESTIONS.RIGHT_ANSWER : SOUND_EFFECTS.QUESTIONS.WRONG_ANSWER;

        const outcomeEffectPromise = AudioService.play(resultAudioToPlay);
        const revealPromise = GameService.revealQuestion({
            isCorrect: isCorrect,
            team: 1,
            step
        });

        setIsLoading(false);
        setIsAnswerable(false);
        await Promise.all([outcomeEffectPromise, revealPromise]);
    }

    const handleSubmit = () => {
        if (selectedOptions.length === 0) {
            ToastUtil.toastWarning(t('toasts.missing_option.title'), t('toasts.missing_option.message'));
            return;
        }

        const answers = selectedOptions.sort((a, b) => a - b);
        const params = {step, answers};

        if (isDemonstration) {
            handleRevealDemoQuestion(answers);
            return;
        }

        if (isTournament) {
            handleReviewTournamentQuestion(answers);
            return;
        }

        setIsLoading(true);

        if (isGameMaster) params.team = store.getState().session.showBoardForTeam;

        TeamService.updateQuestionAnswer(params).then(result => {
            if (result) {
                ToastUtil.toastSuccess(t('toasts.update_success.title'), t('toasts.update_success.message'), {soundEffect: false});
            } else {
                ToastUtil.toastDanger(t('toasts.update_error.title'), t('toasts.update_error.message'));
            }

            setSelectedOptions([]);
        }).catch(error => {
            console.error(error);
            ToastUtil.toastDanger(t('toasts.update_error.title'), t('toasts.update_error.message'));

            LoggerService.error(error, {
                action: 'update question answer - team board',
                params: params
            });
        }).finally(() => setIsLoading(false));
    };

    useEffect(() => {
        if (isDemonstration && revealedDemoQuestion) {
            setShowSubmitButton(false);
            setIsLoading(false);
            setIsAnswerable(false);

            setSelectedOptions(DemoSessionService.getSelectedQuestionOptions());
        }
    }, [revealedDemoQuestion, revealDemoQuestion, isDemonstration]);

    useEffect(() => {
        if (showResult) {
            setIsAnswerable(false);
            setShowSubmitButton(false);
            setSelectedOptions(answer);
        } else if (isTournament) {
            setIsAnswerable(true);
            setShowSubmitButton(true);
        }
    }, [isTournament, showResult]);

    return (
        <div
            className={"question-challenge h-100 w-100 d-flex flex-column justify-content-center align-items-center " + elClass}>

            {isMobile && (<QuestionChallengeButtons
                isAnswerable={isAnswerable}
                isLoading={isLoading}
                showExplanation={showExplanation}
                onAdvanceClick={handleAdvanceGame}
                onShowExplanationClick={handleToggleShowExplanation}
            />)}

            {!showExplanation && (
                <div
                    className='w-100 h-100 d-flex justify-content-center'>
                    <QuestionCard questionCard={card}
                                  isCorrect={isCorrect}
                                  showResult={showResult}
                                  showOutcome={!isAnswerable}
                                  answarable={isAnswerable}
                                  isDesignatedToATeam={true}
                                  isLoading={isLoading}
                                  showSubmitButton={showSubmitButton}
                                  onSubmit={handleSubmit}
                                  onSelectedOptionsUpdate={setSelectedOptions}
                                  selectedOptions={selectedOptions}
                                  submittedOptions={answer}/>
                </div>
            )}

            {showExplanation && (
                <div
                    className='w-100 h-100 d-flex justify-content-center'>
                    <QuestionExplanationCard questionCard={card}/>
                </div>
            )}


            {!isMobile && (<QuestionChallengeButtons
                isAnswerable={isAnswerable}
                isLoading={isLoading}
                showExplanation={showExplanation}
                onAdvanceClick={handleAdvanceGame}
                onShowExplanationClick={handleToggleShowExplanation}
            />)}
        </div>
    );
}

export default memo(QuestionChallenge);